export function formatCNPJ(cnpj: string) {
	if (!cnpj) return '';
	return cnpj.replace(
		/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
		'$1.$2.$3/$4-$5',
	);
}

export function getOS() {
	const userAgent = navigator.userAgent.toLowerCase();
	if (userAgent.includes('windows')) return 'Windows';
	if (userAgent.includes('mac os')) return 'macOS';
	if (userAgent.includes('linux')) return 'Linux';
	if (userAgent.includes('android')) return 'Android';
	if (userAgent.includes('iphone') || userAgent.includes('ipad')) return 'iOS';
	return 'Desconhecido';
}
